



























































































interface Profile {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

interface Errors {
  name: Array<string>;
  password: Array<string>;
}

interface Warnings {
  password: Array<string>;
}

import PasswordValidationFields from '@/components/forms/PasswordValidator.vue';
import {Login} from '@/router';
import Component from "vue-class-component";
import GenericForm from '@/components/forms/GenericForm.vue';
import { Watch } from 'vue-property-decorator';
import { Store } from '@/store/store';

@Component({
  components: {
    PasswordValidationFields,
    GenericForm,
  },
  metaInfo: {
    title: 'Register SV Admin',
  }
})
/**
 * Intended for SV Admin registration
 */
export default class SimplyVideoRegister extends Store {
  componentTitle ='Register SV Admin'
  email:string = '';
  profile: Parameters<typeof Store.prototype.registerSimplyVideoAdmin>[0]['form'] = {
    name: {
      value: '',
      errors: []
    },
    last_name: {
      value: '',
      errors: []
    },
    password: {
      value: '',
      errors: []
    },
    password_confirmation: {
      value: '',
      errors: []
    },
    email:{
      value: '',
      errors: []
    }
  }
  errors:Errors = {
    name: [],
    password: [],
  };
  warnings:Warnings = {
    password: [],
  };
  completed:boolean = false;
  failed:boolean = false
  
  @Watch('profile', {deep: true})
  onProfile() {
    const output = this.profile.name.value &&
      this.profile.last_name.value &&
      this.profile.password.value &&
      this.profile.password_confirmation.value ?
      true : false;

    this.completed = output;
  }

  async mounted() {
    const {token} = this.$route.params;
    if (!token) {
      // No token provided
      this.$toast.error('No Token Provided');
    }
    const {data} = await this.$store.dispatch('registerSimplyVideoAdminMagicToken', token).catch((err) => {
      this.failed = true;
    });
    this.email = data.email;
  }
  
  async register() {
    // Send form
    if (!this.completed) {
      // Form is not completed
      this.$toast.error('Form Is Incomplete')
      return;
    }

    const token = this.$route.params.token
    const email = this.email;

    this.registerSimplyVideoAdmin({
      form: {...this.profile},
      token: this.$route.params.token
    }).then(() => {
      this.$toast.success('You Are Now A Registered SimplyVideo Admin');
      this.$router.push(Login);
    }).catch((err) => {
      this.$toast.error('We Couldnt Register You At This Time');
    });
  }
  /**
   * Gets invoked when the passwords match
   * @arg {Boolean} match
   * @arg {String} pass
   * @vuese
   * @returns {void}
   */
  passwordMatch(valid: boolean, password?: string) {
    const matchError = 'Passwords Do Not Match';
    if (!valid) {
      if (!this.errors.password.includes(matchError)) {
        this.errors.password.push(matchError);
      }
    } else {
      this.errors.password.splice(this.errors.password.indexOf(matchError, 1), 1);
    }

    this.profile.password.value = password || '';
    this.profile.password_confirmation = this.profile.password; // Bit naughty but it's valid
  }
  /**
   * Returns the strength of the password
   * @arg {Boolean} match
   * @arg {String} pass
   * @vuese
   * @returns {void}
   */
  passwordStrength(score: number) {
    const matchError = 'Password Is Not Good Enough';
    if (score < 2) {
      if (!this.warnings.password.includes(matchError)) {
        this.warnings.password.push(matchError);
      }
    } else {
      this.warnings.password.splice(this.warnings.password.indexOf(matchError, 1), 1);
    }
  }
}
